div#supported-platform-pill {
  padding-left: 10px;
  padding-right: 5px;
}

div#supported-platform-pill .pill {
  display: inline;
  text-transform: uppercase;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  font-size: 10px;
  text-align: center;
  color: white;
  user-select: none;
}

div#supported-platform-pill .pill.privileged {
  background-color: #2D9E94;
}

div#supported-platform-pill .pill.view-only {
  background-color: #DEA816;
}

[sie-tooltip] {
  font-family: 'SSTPro-Roman', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

[sie-tooltip]:before {
  background-color: black;
  color: white;
}

[sie-tooltip]:after {
  border-top: 5px solid black;
}