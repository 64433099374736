body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#global-header,
#global-footer {
  flex: none;
}

#root {
  flex: 1 0 auto;
  width: 100%;
}
