#npComm-metadata-modal .modal-header {
    border-bottom: none;
}

#npComm-metadata-modal .modal-title {
    font-size: 18px;
    font-weight: 600;
}

#npComm-metadata-modal .modal-dialog {
    margin-top: 65px;
    min-width: 800px;
    height: 85vh;
    overflow-y: auto;
}

#npComm-metadata-modal .modal-dialog hr {
    margin-bottom: 10px;
    margin-top: 0px;
}

#npComm-metadata-modal .modal-content {
    padding: 10px 25px;
}

#npComm-metadata-modal .metadata-section__title:not(:first-child) {
    margin-top: 30px;
}
#npComm-metadata-modal .metadata-section__title {
    margin-bottom: 15px;
}

#npComm-metadata-modal .metadata-section .form-group {
    margin-bottom: 5px;
}

#npComm-metadata-modal .metadata-section__image p {
    font-size: 13px;
    color: grey;
}

#npComm-metadata-modal .metadata-section__image p.text-danger {
    color: #E41017;
}

#npComm-metadata-modal .metadata-section-supported-platforms {
    display: inline-flex;
    padding-left: 10px;
    padding-bottom: 5px;
    align-items: center;
}

#npComm-metadata-modal .reference-name-container {
    padding-left: 10px;
}

#npComm-metadata-modal .reference-name-container > div {
    display: flex;
    padding-top: 5px;
}

#npComm-metadata-modal .reference-name-container > div > div:nth-child(1) {
    padding-top: 5px;
    padding-right: 20px;
}
#npComm-metadata-modal .reference-name-container > div > div:nth-child(2) {
    margin-top: -25px;
    padding-bottom: 10px;
    width: 45%;
}

#npComm-id {
    color: grey;
}

#npComm-metadata-modal .modal-footer {
    border-top: none;
}

#npComm-metadata-modal .modal-footer .btn {
    width: 100px;
    margin-left: 15px;
}

#npComm-metadata-modal div.sie-select div.prohibit-delete div.sie-control__multi-value__remove {
    display: none !important;
}

#npComm-metadata-modal .platform-error {
    float: left;
    color: #b7504f;
    font-size: 12px;
}

#npComm-metadata-modal .platform-error .error-icon {
    padding-right: 5px;
}

#npComm-metadata-modal div.integration-warning {
    padding-left: 1em;
    padding-right: 1em;
}