div.context-image-uploader.image-uploader,
div.context-image-uploader.image-uploader .dzu-dropzone,
div.context-image-uploader.image-uploader .dzu-inputLabel{
  width: auto !important;
}

div.image-uploader div.bottom-bar {
  display: flex;
  justify-content: space-between;
  font-weight: lighter;
  height: 18px;
}

div.image-uploader div.left {
  font-size: 13px;
}

div.image-uploader div.right {
  color: gray;
  font-size : 13px;
}

div.image-uploader .dzu-dropzone{
  overflow: hidden !important;
  border: 1px dashed #979797;
  border-radius: 2.5px;
  background-color:#f7f7f7;
  height: 160px;
  margin: 0px !important;
  padding: 40px 0;
  margin-bottom: 8px;
  /* text-align: center; */
}

div.image-uploader  .dzu-dropzone .dzu-inputLabel{
  color: inherit;
  font-family: inherit;
  font-weight: normal;
  font-size: 100%;
  margin: 0px;
  height: 160px;
}

div.image-uploader .dzu-dropzone input[type=file]{
  display: none;
}

div.image-uploader .fa_custom {
  background-color: gray
}
div.image-uploader .preview-image{
  text-align: center;
  border: 1px dashed #979797;
  border-radius: 2.5px;
}

div.image-uploader img {
  max-height: 160px;
  margin-bottom: 0px;
}

div.image-uploader .error-block {
  overflow: hidden;
  border-width:1px;
  border-radius: 2.5px;
  background-color: #f7f7f7;
  border-style: dashed;
  height: 160px;
  padding: 40px 0;
  margin-bottom: 8px;
}

div.image-uploader .state {
  margin-left: 80px;
  margin-right: 30px;
  margin-top: 50px;
}

div.image-uploader .btn-link{
  color: gray;
  margin: 0px;
}

div.image-uploader .btn-click{
  cursor: pointer;
}

div.context-image-uploader.image-uploader > .bottom-bar .sie-status > .text-danger {
  font-size: 12px;
}

div.uploading-image-content {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #979797;
  background-color: #f7f7f7;
}