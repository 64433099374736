div#lock-status-container {
    padding-top: 20px;
}

div#lock-status-container .lock-status-indicator {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: .1rem;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;
    height: 30px;
    font-size: 10px;
    line-height: 30px;
    text-align: center;
    background-color: rgb(219, 219, 219);
    font-weight: bold;
    color: white;
    user-select: none;
}

div#lock-status-container .lock-status-indicator.partially-locked {
    background-color: #DEA816;
}

div#lock-status-container .lock-status-indicator.locked {
    background-color: #C5474B;
}

div#lock-status-container .lock-status-indicator.unlocked {
    background-color: #2D9E94;
}