body {
   overflow-y: hidden;
}

/*.App {
  text-align: center;
}*/

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.iframe-embedded-app {
  width: 100%;
  height: calc(100vh - 140px);
  overflow: hidden;
  border: none;
 }

.custom-checkbox input, .custom-radio input {
    opacity: 1;
}

.breadcrumb-container {
   padding-left: 0px;
   padding-right: 0px;
}

.status-error {
   display: flex;
   justify-content: center;
}

.context-viewer-container {
   height: 50px;
   display: flex;
   align-items: center;
   color: #fff;
   background: #0072ce;
   margin-right: 0px;
   margin-left: 0px;
   font-size: 120%;
}

.context-viewer-container > div > span {
   margin-left: 15px;
}

.context-viewer-img {
   margin-left: 15px;
}

.context-viewer-img > img {
   height: 25px;
   vertical-align: text-bottom;
}

.placeholder-img {
   margin-left: 10px;
   padding-left: 25px;
   background: #CCC;
}

.context-viewer-context-type {
  font-size: 9px;
  display: flex;
}

.context-viewer-metadata-container {
  display: flex;
  align-items: center;
}

.context-viewer-metadata-container > div {
  display: inline-block;
}

.context-viewer-metadata-container > div > div {
  margin-top: -5px;
}

.context-viewer-value {
   opacity: 0.7;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Remove once css change has been merged into component library */
.sie-local-nav li.nav-dropdown.active, .sie-local-nav li.single-link.active {
  z-index: 0;
}

div#global-footer {
   position: absolute;
   bottom: 0px;
   width: 100%;
   height: 62px;
   margin: 0 auto;
   overflow: hidden;
}

div#iframe-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 203px);
    padding-left: 0;
    padding-right: 0;
}

.context-viewer-container ~ div#iframe-container {
    height: calc(100vh - 253px);
}

/* Context viewer css (Start) */

.sie-context-switcher__control {
   background-color: #0072ce !important;
   border-color: #0072ce !important;
   min-height: 50px !important;
}

.sie-context-switcher__control--is-focused {
   border: 0px !important;
   box-shadow: 0 0 0 1px transparent !important;
   &.has-error {
    height: 38px;
    box-shadow: transparent !important;
   }
}

.sie-context-switcher__dropdown-indicator {
   color: #FFF !important;
}

.sie-context-switcher__control--is-focused.menu-open {
  &:not(.has_error) {
    box-shadow: inset 0px 1px 0px 0px #0072ce !important;
  }
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  &:not(.multi-enabled) {
    height: 50px;
    .sie-context-switcher__value-container {
      & > div:last-child {
        width: 100%;
      }
    }
  }
}

.sie-context-switcher__menu {
   z-index: 9999 !important;
   margin-top: 0px !important;
   border-top-left-radius: 0px !important;
   border-top-right-radius: 0px !important;
   box-shadow: 0 -1px 0 0 inset #FFFFFF !important;
   border-left: 1px #FFFFFF solid !important;
   border-right: 1px #FFFFFF solid !important;
   border-top: 0px !important;
}

.sie-context-switcher__menu-list {
   padding-top: 0px !important;
   padding-bottom: 0px !important;
}

.sie-context-switcher__option {
   background-color: #FFFFFF;
   color: #585858;
   font-family: 'SST Pro Roman';
   min-height: 40px;
   width: 100%;
}

.sie-context-switcher__option > div > div > span,
.sie-context-switcher__option > div > div > div > span,
.sie-context-switcher__single-value > div > div > span,
.sie-context-switcher__single-value > div > div > div > span{
   margin-left: 15px !important;
}

.sie-context-switcher__option > div > div > div > span.context-viewer-type,
.sie-context-switcher__option > div > div > div > span.context-viewer-value,
.sie-context-switcher__option > div > div > span.context-viewer-context-type {
   color: #585858 !important;
}

.sie-context-switcher__option--is-focused {
   background-color: #F5F5F5 !important;
}

.sie-context-switcher__indicator-separator {
   display:none !important;
}

.sie-context-switcher__option--is-selected {
   background-color: #F5F5F5 !important;
   box-shadow: inset 1px 0px 0px 0px #67CAFF;
   border-right: 1px solid #67CAFF;
   color: #585858 !important;
}

.sie-context-switcher__option {
   background-color: #FFF;
   display: flex !important;
   align-items: center;
   padding-left: 8px !important;
}

.sie-context-switcher__placeholder {
  color: #FFFFFF !important;
}

.sie-context-switcher__single-value {
   color: #fff !important;
   background-color: #0072ce !important;
   font-family: 'SST Pro Roman';
}

.sie-context-switcher__control .sie-context-switcher__value-container .sie-context-switcher__single-value {
   margin-left: 0;
   margin-right: 0;
}

/* Context viewer css (End) */

.notification-action {
   top: 10px;
   height: unset;
   line-height: 30px !important;
}

span#context-edit {
    cursor: pointer;
    font-family: SST Pro Roman;
    font-size: 18px;
    padding: 12px;
}

span#context-edit:hover {
    background: #00a2ff
}

.sie-local-nav .dropdown-menu li.nested-dropdown {
    margin-right: 0px;
    padding: 0px;
    margin-left: 5px;
    margin-top: 5px;
}

.sie-local-nav .dropdown-menu li.nested-dropdown > a {
    height: 29px;
}

.sie-local-nav .dropdown-menu li.nested-dropdown.active > a {
    background: #FFFFFF;
}
li.nested-dropdown > a > span.caret {
    margin-top: -4px;
    transform: rotate(135deg);
}

li.nested-dropdown > ul.dropdown-menu {
    position:absolute;
    top:0px;
    left:100%;
    padding-bottom: 5px;
}
