div.image-uploader-default-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    color: #979797;
}

div.image-uploader-default-content-container > img {
    height: 40px;
    margin: 0;
    margin-bottom: 5px;
}

div.image-uploader-default-content-container > div.click-to-browse {
    margin-top: 10px;
    margin-bottom: 15px;
}

div.image-uploader-default-content-container > span.glyphicon {
    font-size: 3em;
    margin-bottom: 0.3em;
}


div.uploaded-image {
  height: 160px;
  background-color: #f7f7f7;
}

div.uploaded-image img {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
}

div.uploaded-image button.close {
    position: absolute;
    top: 10px;
    right: 25px;
}
